import React, { useEffect, useState } from 'react';
// import logo from '../../assets/logo.svg';
import './App.css';

import axios from 'axios';

function App() {
  const [idToken, setIdToken] = useState('')
  const [profile, setProfile] = useState({})

  const [explorerPath, setExplorerPath] = useState('') // Current file explorer path
  const [doRefresh, refreshExplorer] = useState(true)

  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [folderCreatorOpen, setFolderCreatorOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [name, setname] = useState('');
  const [pass, setpass] = useState('');
  const [login, setlogin] = useState(false);

  const handleFileChange = (e) => {
    setname(e.target.value);
  };
  const handleFileChange2 = (e) => {
    setpass(e.target.value);
  };
  useEffect(()=>{
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if(token){
      setlogin(true)
    }
    else{
      setlogin(false)
    }
  },[])
  // const handleUpload = async () => {
  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {
  //     const response = await axios.post('http://localhost:5000/upload', formData);
  //     // console.log(response)
  //     setFileUrl(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // function loginapi(username, password) {
  //   fetch('http://localhost:5000/login', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json',
  //     "Access-Control-Allow-Origin": "*"
  //   },
  //     body: JSON.stringify({ username, password }),
  //     credentials: 'include', // Important for sending cookies
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     if (data.success) {
  //       console.log(data,document.cookie,"see")
  //       // localStorage.setItem("data",data)
  //       // document.cookie = 'token=your_token_value; domain=.yourdomain.com; Secure; SameSite=None';

  //       // Redirect to dashboard or perform necessary actions on successful login
  //       setlogin(true)
  //       window.location.href = 'http://localhost:3001/';
  //     } else {
  //       // Handle login failure
  //       console.log(data.message);
  //     }
  //   })
  //   .catch(error => console.error('Error:', error));
  // }
  function setCookie(name, value, days, domain) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    const cookieDomain = domain ? `; domain=${domain}` : '';
    console.log(cookieDomain)
    document.cookie = `${name}=${value || ''}${expires}${cookieDomain}; path=/`;
  }
  function deleteCookie(name, domain) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    setlogin(false)
  }
  
  
async function loginapi(username, password) {
  try {
    const response = await axios.post('https://backend-test-cookie-1.onrender.com/login', {
      username,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
      },
      withCredentials: true, // Important for sending cookies
    });

    const data = response.data;
    if (data.success) {
      console.log(data, document.cookie, "see");
      setCookie('token', data.token, 1, 'accredian.com');
      // localStorage.setItem("data", data)
      // document.cookie = 'token=your_token_value; domain=.yourdomain.com; Secure; SameSite=None';

      // Redirect to dashboard or perform necessary actions on successful login
      setlogin(true);
      window.location.href = 'https://dashboard-project2.accredian.com/';
    } else {
      // Handle login failure
      console.log(data.message);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}
const logout=async ()=>{
  const response = await axios.post('https://backend-test-cookie-1.onrender.com/logout', {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true, // Important for sending cookies
  });
  const data = response.data;
  if (data.success) {
    setlogin(false)
  }
}
  return (
    <div className="App">
      

      <div>
      <h1>Website login = {login?"true":"false"}</h1>
    <input onChange={handleFileChange} type="text" placeholder='enter your username'></input>
    <input  onChange={handleFileChange2} type="password" placeholder='enter your password'></input>
    <button onClick={()=>{loginapi(name,pass)}}>login</button>
    <button onClick={()=>{deleteCookie('token','accredian.com')
  }}>logout</button>
    </div>

      
    </div>
  );
  };
  // Perform login


 


export default App;
